import React from "react";
import { getActiveClasses } from "../utility/get-active-classes";
import { Collapse, Tooltip } from "@material-ui/core";
import FacebookSvg from "../images/facebook.svg";
import twitterSvg from "../images/twitter.svg";
import instagramSvg from "../images/instagram.svg";
import { Link } from "gatsby";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

export default function ListingCard({ isActive, listing, ...props }) {
  return (
    <section
      {...props}
      className={getActiveClasses([
        `p-6 border border-transparent hover:border-gray-300 rounded hover:shadow cursor-pointer transition-all transition-250`,
        {
          "shadow border-gray-300": isActive,
        },
      ])}
    >
      <div className="flex sm:text-2xl text-lg">
        <h2 className="font-medium">{listing.name}</h2>
        <span className="ml-auto text-gray-600">
          {listing.deliveryLocations[0].name}
        </span>
      </div>
      <Collapse in={isActive}>
        <p className="mt-2">{listing.description}</p>
        <table className="sm:text-lg text-base w-full cursor-default mt-2">
          <tbody>
            <UrlRow
              title="Website"
              url={listing.website}
              onClick={() => {
                trackCustomEvent({
                  category: "Listing",
                  action: "Click",
                  label: "Website",
                  value: listing.name,
                });
              }}
            />
            {Array.isArray(listing.deliveryLocations) &&
              !!listing.deliveryLocations.length && (
                <Row
                  content={listing.deliveryLocations.map(
                    ({ name, state }, index) => (
                      <>
                        <Link
                          key={name + state}
                          className="hover:underline hover:text-blue-600"
                          href={`/l/${name.replace(" ", "-")}-${state}`}
                          onClick={() => {
                            trackCustomEvent({
                              category: "Listing",
                              action: "Click",
                              label: "Delivery Location",
                              value: `${listing.name} ${name} ${state}`,
                            });
                          }}
                        >
                          {name}
                        </Link>
                        {index + 1 === listing.deliveryLocations.length
                          ? ""
                          : ", "}
                      </>
                    )
                  )}
                  title="Delivery Locations"
                />
              )}
            {listing.email && (
              <Row
                content={
                  <a
                    className="hover:underline hover:text-blue-600"
                    href={`mailto:${listing.email}`}
                    onClick={() => {
                      trackCustomEvent({
                        category: "Listing",
                        action: "Click",
                        label: "Email",
                        value: listing.name,
                      });
                    }}
                  >
                    {listing.email}
                  </a>
                }
                title="Email"
              />
            )}

            {listing.phone && (
              <Row
                content={
                  <a
                    className="hover:underline hover:text-blue-600"
                    href={`tel:${listing.phone}`}
                    onClick={() => {
                      trackCustomEvent({
                        category: "Listing",
                        action: "Click",
                        label: "Phone",
                        value: listing.name,
                      });
                    }}
                  >
                    {listing.phone}
                  </a>
                }
                title="Phone number"
              />
            )}
            {listing.social &&
              (listing.social.facebook ||
                listing.social.twitter ||
                listing.social.instagram) && (
                <Row
                  content={
                    <>
                      {listing.social.facebook && (
                        <Tooltip title={`Facebook`}>
                          <a
                            aria-label="Facebook"
                            className="inline-flex"
                            href={listing.social.facebook}
                            onClick={() => {
                              trackCustomEvent({
                                category: "Listing",
                                action: "Click",
                                label: "Facebook",
                                value: listing.name,
                              });
                            }}
                          >
                            <img alt="Facebook" src={FacebookSvg} />
                          </a>
                        </Tooltip>
                      )}
                      <div className="pl-4 inline-block">
                        {listing.social.twitter && (
                          <Tooltip title={`Twitter`}>
                            <a
                              aria-label="Twitter"
                              className="inline-flex"
                              href={listing.social.twitter}
                              onClick={() => {
                                trackCustomEvent({
                                  category: "Listing",
                                  action: "Click",
                                  label: "Twitter",
                                  value: listing.name,
                                });
                              }}
                            >
                              <img alt="Twitter" src={twitterSvg} />
                            </a>
                          </Tooltip>
                        )}
                      </div>
                      <div className="pl-4 inline-block">
                        {listing.social.instagram && (
                          <Tooltip title={`Instagram`}>
                            <a
                              aria-label="Instagram"
                              className="inline-flex"
                              href={listing.social.instagram}
                              onClick={() => {
                                trackCustomEvent({
                                  category: "Listing",
                                  action: "Click",
                                  label: "Instagram",
                                  value: listing.name,
                                });
                              }}
                            >
                              <img alt="Instagram" src={instagramSvg} />
                            </a>
                          </Tooltip>
                        )}
                      </div>
                    </>
                  }
                  title={`Social`}
                ></Row>
              )}
          </tbody>
        </table>
      </Collapse>
    </section>
  );
}

function Row({ title, content }) {
  return (
    <tr>
      <td className="text-gray-700 py-4 text-base whitespace-no-wrap cursor-text align-top hidden sm:table-cell uppercase font-bold">
        {title}
      </td>
      <td className="sm:pl-8 py-4 cursor-text">
        <h2 className="text-gray-700 font-bold sm:hidden uppercase">{title}</h2>
        {content}
      </td>
    </tr>
  );
}

function UrlRow({ title, url, ...params }) {
  if (!url) return null;

  return (
    <Row
      content={
        <a
          className="hover:underline hover:text-blue-600"
          href={`${url}`}
          {...params}
        >
          {url.replace(`https://`, ``).replace(`http://`, ``)}
        </a>
      }
      title={<div>{title}</div>}
    />
  );
}
