import React from "react";
import ListingCard from "./ListingCard";

export default function ListingList({ listings, filter }) {
  const [activeIndex, setActiveIndex] = React.useState(null);

  return (
    <ul className="px-3">
      {listings
        .filter(filter || (() => true))
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
        .map((listing, index) => (
          <li key={listing.name + listing.mainLocation}>
            <ListingCard
              listing={listing}
              onClick={() => setActiveIndex(index)}
              isActive={activeIndex === index}
            />
          </li>
        ))}
    </ul>
  );
}
