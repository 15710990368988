import React from "react";
import Button from "../components/button";
import ReactGA from "react-ga";
import { Link } from "gatsby";

export default function ListBusinessButton() {
  const onListBusinessClick = () => {
    ReactGA.event({
      category: "List Business Click",
      action: "User clicked on list business",
    });
  };

  return (
    <Link to="/list" onClick={onListBusinessClick}>
      <button className="inline-block transition-bg transition-100 transition-ease hover:bg-green-500 bg-green-600 rounded-lg font-bold text-white px-4 py-1 tracking-wider text-lg">
        Suggest a business
      </button>
    </Link>
  );
}
